
<template>
    <div class="container">
      <div class="title">
        <span class="title-left"> <img src="@/assets/img/users/ai.png" alt="" style="margin-right:8px">
            Partner</span>
        <div class="btn_export flex_center"  @click="exportResult" ><img src="@/assets/img/search/export_ic1.png" />Export</div>
  
      </div>
      <div class="table">
        <div class="right_cont_box">
          <div class="table_box">
            <el-table ref="treeTable" :data="parentTablelist" border style="width: 100%">
              <!-- 空数据状态的插槽 -->
              <template slot="empty">
                <noDate />
              </template>
              <el-table-column label="Paretner Name"  align="left"   class-name="company_link" show-overflow-tooltip >
                <template slot-scope="scope">
                  <div  @click="setid1(scope.row)" exact class="tbl_company" v-show="!showTranslate">{{ scope.row.pnameEn}}</div>
                        <div  @click="setid1(scope.row)" exact class="tbl_company" v-show="showTranslate">{{ scope.row.pname  }}</div>
                </template>
            </el-table-column>
              <el-table-column label="Related Company" :prop="showTranslate==true?'companyName':'companyNameEn'"  align="left" show-overflow-tooltip
                :resizable="false" >
                <template slot-scope="scope">
                  <div  @click="setid(scope.row)" exact class="tbl_company" v-show="!showTranslate">{{ scope.row.companyNameEn}}</div>
                        <div  @click="setid(scope.row)" exact class="tbl_company" v-show="showTranslate">{{ scope.row.companyName  }}</div>
                </template>
              </el-table-column>
    
              <el-table-column label="Postion" prop="roleEn"  align="center" show-overflow-tooltip :resizable="false" />
              <el-table-column  label="Subject Postion"  prop="SpEn" align="center" show-overflow-tooltip :resizable="false" />
           
            </el-table>
            <div  class="page_i_box">
              <Page :totalFont=true :total=page.total @onPageChange="onPageChangeList" @currtentPageChange="currtentPageChange"></Page>
              <div class="search_rsult_txt">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum}} results</div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="ecahrts-header"> <span class="title-left"> <img src="../../assets/img/company/echarts.png" alt=""
              style="margin-right:8px"> Charts</span>
  
          <div class="head_loging" :class="loginStatus">
            <el-switch v-model="loginStatus" @change="loginPop"  active-color="#13ce66"></el-switch>
  
          </div>
        </div>
        <div v-show="loginStatus" class="ecahrtstu"> 
      <div ref="trendChart" class="trendEchartBox1" />
      <div class="echartsBtns">
            <el-button icon="el-icon-plus" :disabled="level >= 3" @click="changeLevel(level + 1)" />
            <el-button icon="el-icon-minus" :disabled="level <= 1" @click="changeLevel(level - 1)" />
            <el-button v-for="(item,index) in rolelist" :key="index" @click="changechats(item.role)" >{{ item.name }}</el-button>
          </div>
    </div>
      </div>
  
      <el-dialog :visible.sync="exportDialog" center :close-on-click-modal="false" custom-class="large_dialog" :append-to-body="true">
      <div class="large_detail_cont">
        <div class="dialog_detail_title">Please select a company name</div>
        <div class="company_box">
          <div class="comp_top_check flex_center_between_box">
            <el-checkbox v-model="checkAll" @change="checkedAllCompany">Select All</el-checkbox>
            <div class="comp_search">
              <input class="coll_search_inpt" v-model="filterName" type="text" placeholder="Enter company name" />
              <img class="coll_search_btn" src="../../assets/img/head_search_ic.png" @click="hanldFilterName" />
            </div>
          </div>
          <div class="check_item" v-infinite-scroll="infiniteScroll" :infinite-scroll-disabled="routeLoad || noMore"
            :infinite-scroll-distance="5">
            <el-checkbox-group  v-model="checkedCompanyList">
              <el-checkbox v-for="company in companyList" :label="company" :key="company.aaa_id">{{
                company.pnameEn?company.pname:company.pnameEn
              }}</el-checkbox>
              
            </el-checkbox-group>
            <!-- <el-checkbox-group v-if="show==3" v-model="checkedCompanyList">
              <el-checkbox v-for="company in companyList" :label="company" :key="company.aaa_id">{{
                company.company_name_en
              }}</el-checkbox>
              
            </el-checkbox-group> -->
          </div>
        </div>
        <div class="company_b_txt">{{ checkedCompanyList.length }} enterprises were selected</div>
        <div class="btn_comp_box">
          <download-excel class="export-excel-wrapper" :data="DetailsForm" :fields="json_field" :header="title"
            name="company.xls">
            <button class="btn_export" @click="exportCompany">Export</button>
          </download-excel>
        </div>
      </div>
    </el-dialog>
    </div>
  </template>
  <script>
    import { getpersonnel } from '../../api/userApi';
  import noDate from "@/components/tableNoDate";
  import { getCompanyDetailMenuList, ordersAdd ,getIsCollect} from '../../api/companyApi';
  import Page from "@/components/page";
  import { data } from './data';
  import { formatterTxt, formatterTxtTips,roleformatterTxtTips, roleformatterTxt,formatList } from './utils'
  import { mixins1 } from '@/mixins/index';
  export default {
    components: { noDate, Page },
    mixins: [mixins1],
    props:{
      showTranslate: {
      type: Boolean,
      default: false,
    },
    },
    data() {
      return {
        level :2,
        companyAaaid:'156021447566',
        comIcon1: 'image://' + require('@/assets/img/echarts/comIcon1.png'),
      comIcon2: 'image://' + require('@/assets/img/echarts/comIcon2.png'),
      comIcon3: 'image://' + require('@/assets/img/echarts/comIcon3.png'),
      comIcon4: 'image://' + require('@/assets/img/echarts/comIcon4.png'),
      comIcon5: 'image://' + require('@/assets/img/echarts/comIcon5.png'),
        loginStatus: false,//登录状态
  
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0
        },

        echartsdate2:[],
        rolelist:[
        { name:'Legal Principal',role:'1' },
        { name:'Shareholder',role:'2' },
        { name:'Manager',role:'3' },
        { name:'Agent',role:'4' },
        // { name:' Sister Companies',role:13 }
    ],
        checkedCompanyList:[],
        noMore: false, // 控制滚动禁用
      routeLoad: false, // 控制滚动禁用
      filterName: '',
      companyList:[],
      parentTablelist:[],
      exportDialog: false,
        fromPage: 'partner',
        checkAll: false,
        title: 'Partner',
        json_field: {
        'Paretner Name': 'pname',
        'Related Company': 'companyNameEn',
        'Postion': 'roleCn',
        'Subject Postion': 'SpEn',
        
      },
      DetailsForm: [],
        // showTranslate:false,
        icon: '',
        parentTable: [],
  
      }
    },
    computed: {
      openMenu: {
        get() {
          return this.menuTitleList.map((i) => {
            return i.title;
          })
        },
        set() { }
  
      }
    },
    watch: {
  
      $route: {
        handler(val, oldval) {
          // console.log(val.path.replace('/', ''));//新路由信息
          this.Indexy = val.path.replace('/', '')//当前活动路由
        },
        // 深度观察监听
        deep: true,
     
      },
      showTranslate:{
        handler(val, oldval) {
 
        },
        // 深度观察监听
        deep: true
      }

    },
    mounted() {
      // this.echarts()
      this.huoqugetpersonner()
    },


    methods: {

      exportResult() {
      this.pageNo = 1
      this.pageSize = 20
      // this.getRouteList()
      this.exportDialog = true
      this.huoqugetpersonner()
    },
             //弹窗全选公司
             checkedAllCompany(val) {
      this.checkedCompanyList = val ? this.companyList : []
    },
        //模糊查询
        hanldFilterName() {
      let list = this.companyList
      let keyWord = this.filterName
      var arr = []
      if (keyWord == '') {
        return this.companyList
      } else {
        arr = list.filter((item) => {
          return item.company_name_en.includes(keyWord)
        })
      }
      this.companyList = arr
    },
    // 滚动加载方法
    infiniteScroll() {
      this.routeLoad = true
      this.pageNo += 1 // 页码每次滚动+1
      // this.getRouteList()
      this.huoqugetpersonner()
    },
      setid1(row){
        // 跳转路又高亮
        this.$emit('updateSubLink',0)
        console.log(row);
      // sessionStorage.setItem('User', JSON.stringify(row))
      this.getPowerPersonDetail(row);
      // this.$router.push({
      //   path: '/users/background?pid=' +row.pid + '&name=' +row.pnameEn+'&companyCountry=' + 'CN',
        
      // })
    },
    setid(row) {
      console.log(row);
      sessionStorage.setItem('company', JSON.stringify(row))
     let routeData= this.$router.resolve({
        path: '/company/companysnapshot?id3a=' + row.aaaId + '&companyCountry=' + row.countryCode,
      })
      window.open(routeData.href, '_blank');
    },
         //PersonDetail 是否能链接进去 权限
    async getPowerPersonDetail(row) {
   
            let params = 'type=8' + '&code=' + row.pid;
            let res = await getCompanyDetailMenuList(params);
            if (res && res.code) {
                let hasPower = res.data[0].power;
                if (hasPower || (!hasPower && (await this.getOrdersAddPerson(row)))) {
                    sessionStorage.setItem('User', JSON.stringify(row));
                  let routeData= this.$router.resolve({
                        path: '/users/background?pid=' + row.pid + '&name=' + row.pnameEn + '&companyCountry=' + row.countryCode+'&information=Search Person',
                    });
                    window.open(routeData.href, '_blank');
                }
            }
        },
              /**
         *用户购买报告 "type": 14 ：personDetail
        */
        getOrdersAddPerson(row) {
            const type = 14;
            console.log(row.countryCode=='');
            let params = {
                companyName: row.pnameEn, //人员名称
                aaaId: row.pid, //人员pid
                companyCountry: row.countryCode, // 国家二位编码
                method: '0', //支付方式  0：帐号余额 1：支付宝 2：微信 ，3 国际万事达卡 支付 传  0   //必须
                methodType: '0', //支付类型： 1 免费额度 ，0 充值额度       //(非必须)
                type: type, //订单类型 //必须
            };
            return new Promise((resolve, reject) => {
                ordersAdd(params).then(result => {
                    let data = result;
                    if (data && data.code) {
                      this.$message({
                        message: data.msg,
                        type: 'success',
                        // duration: 5000,
                    });
                        resolve(true);
                    } else {
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            // duration: 5000,
                        });
                        let that=this
              setTimeout(function(){
                let routeData = that.$router.resolve({
                path: '/account/addCredit',
            });

            window.open(routeData.href, '_blank');
              },2000)
                        resolve(false);
                    }
                });
            });
        },
        //导出
       async exportCompany() {
      // console.log(this.checkedCompanyList);
   
        this.DetailsForm = this.checkedCompanyList
  
    
    },
      async huoqugetpersonner() {
        // console.log(this.showTranslate);
        const pid = this.$route.query.pid
      // const name=this.$route.query.name
        const params={
            // page:1,
            // pageSize:10,
            // roleList:'',
            // name:name,
            pid:pid,
            // industryCodeList:"",
            // companyStatusCodeList:""
        }
            const res = await getpersonnel(params)
            if(res.code){
              let ecahrtsdata =res.data.rows
             console.log(ecahrtsdata );
              this.echartsdate = ecahrtsdata
              this.echartsdate = JSON.parse(JSON.stringify(this.echartsdate[0]).replace(/companyInfoList/g, 'children'))
      this.echartsdate.children = JSON.parse(JSON.stringify(this.echartsdate.children).replace(/companyNameEn/g, 'pname'))
      this.echartsdate.children = JSON.parse(JSON.stringify(this.echartsdate.children).replace(/partnerList/g, 'children'))
      console.log(this.echartsdate);

     
      // this.echartsdate.children.forEach((item,idnex)=>{
        // console.log(`item`, item);

        // if(item.relationList[0].value!='3'){
        //   // this.ecahrtsdata.filter
        //   console.log(`index`, idnex);
        // }

      // })
    //   const detailchild =(child)=>{
    //     // if(!child) return []
    //     return child.filter((item)=>{
    //       if(item.relationList[0].value=='3'){
    //             item.children=detailchild(item.children)
    //       }
    //       return item.relationList[0].value=='3'
    //     })
    //   }
    //  let b= this.echartsdate.children.filter(item=>{
    //   if(item.relationList[0].value=='3'&&item.children){
    //     // console.log(``, );
    //     item.children=detailchild(item.children)
    //   }
    //   return item.relationList[0].value=='3'
    //  })
    //  console.log(`b`, b);

   
      //         this.$nextTick(() => {
      //   this.echarts()
      // })

               
      this.parentTable=res.data.rows[0].companyInfoList
            
               let arr1=[]
              this.parentTable.map((item,index)=>{
                // console.log(item.roleEn);
            //  console.log(item,'A');
              //  console.log( this.parentTable[index],'B');
               this.parentTable[index].partnerList
                this.parentTable[index].partnerList.map(ite=>{
               ite.SpCn=this.parentTable[index].roleCn
               ite.SpEn=this.parentTable[index].roleEn
               ite.aaaId=this.parentTable[index].aaaId
               ite.companyName=this.parentTable[index].companyName
               ite.countryCode=this.parentTable[index].countryCode
               ite.companyNameEn=this.parentTable[index].companyNameEn
               arr1.push(ite)
                })
              })
              this.parentTable=arr1
              // console.log(this.parentTable);
              this.pageList() 
              if(  this.exportDialog ){
          this.companyList=[]
          let list = arr1;
              
              for (let i = 0; i < list.length; i++) {
                  this.companyList.push(list[i]);
              }
              this.companyTblList = this.companyList;
              if (list.length < 10) {
                  this.noMore = true;
              }
              if (this.companyList.length === res.data.total) {
                  this.noMore = true;
              }
              this.routeLoad = false;
        }
            }else{
              this.echartsdate=[]
              this.parentTable=[]
            }
         
              // console.log(this.parentTable);
   
        },


        changechats(val){
console.log(`value`, val);
const filterMenu =menulist=>{
  
        return menulist.filter(item=>
        {
          return item.relationList.some(item=> item.value==val)
      }).map(item=>{
        
        item= Object.assign({},item)
        // console.log(`item`, item);
// console.log(`item`, item);
        if(item.children&&item.children.length>0){
          // console.log(`111`, 111);
          item.children=filterMenu(item.children)
        }
        // item.map(item=>{
        //   console.log(`item`, item);
        // })
        return item
      })
      }
      console.log(`this.echartsdate`, this.echartsdate);
      this.echartsdate2=this.echartsdate
      let ecdata=this.echartsdate.children
      console.log(`ecdata`, ecdata);
      // if(val=='1'){
        console.log(`this.ehc`, this.echartsdate2);
        this.echartsdate2.children= filterMenu(ecdata)
      // }
      this.huoqugetpersonner()
      // if(val=='2'){
      //   this.echartsdate2.children= filterMenu(ecdata)
      // }
      
     
      this.$nextTick(() => {
          this.echarts()
        })

        },
        changeLevel (newLel) {
      this.level = newLel
      this.$nextTick(() => {
          this.echarts()
        })
      // this.treeChart(this.echartData, newLel)
    },
        echarts(ecdata) {
      // console.log(this.$refs);
      // console.log( data);
      const that = this
      let leval=that.level
const initialTreeDepth = leval
// console.log(`that.ecah`, that.ecahrtsdate);
//      that.ecahrtsdate.children.forEach((item,index)=>{
//         console.log(item);
//      })
     let data = that.echartsdate2
     console.log(`data`, data);
      const myChart = this.$echarts.init(this.$refs.trendChart)

      myChart.showLoading()
  myChart.hideLoading();

  const symbol = (data) => {
      //  console.log(data.aaaId);
      //  console.log(data.pid);
        // if (`${data.invtypecode}` === '3') {
        // // 外国公司
        //   return this.comIcon3
        // }
        // if (`${data.invtypecode}` === '5') {
        // // 国营企业
        //   return this.comIcon5
        // }
        // if (data.aaa_id === this.companyAaaid) {
        //   // 主体
        //   console.log(this.comIcon4);
        //   return this.comIcon4
        // }
        if (!data.aaaId) {
          // 个人
          return this.comIcon2
        }
        return this.comIcon1
      }
    const option = {
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove',
          fontSize: 12,
          formatter: (params) => roleformatterTxtTips(params
          )
        },
        series: [
          {
            type: 'tree',
            data: [data],
            top: '18%',
            bottom: '14%',
            // roam: true,
            layout: 'radial',
            symbol: (value, param) => symbol(param.data),
            symbolSize: 30,
            initialTreeDepth,
            animationDurationUpdate: 750,
            emphasis: {
              focus: 'ancestor'
            },
            orient: 'TB',
            label: {
              fontSize: 9,
              formatter: (params) => roleformatterTxt(params),
              rotate: 360
            },
            leaves: {
              label: {
                fontSize: 9,
                formatter: (params) => roleformatterTxt(params),
                rotate: 360
              }
            }
          }
        ]
      }
  myChart.setOption(option);

      // myChart.resize()
    },
    // 分页
    pageList() {
      // console.log(this.parentTable);
   
      this.parentTablelist = this.parentTable.filter(
        (item, index) =>

            index < this.page.pageNo * this.page.pageSize &&
          index >= this.page.pageSize * (this.page.pageNo - 1)
      );
      this.page.total= this.parentTable.length
    },

      onPageChangeList(pageNo) {
        // this.pageNo += 1 
         this.page.pageNo = pageNo
         this.pageList()
      },
      currtentPageChange(pageSize) {
        // this.pageNo += 1 
      this.page.pageSize = pageSize
      // this.init()
      this.pageList()
    },
      tab(item) {
        console.log(item);
      },
      handleChange(val) {
        this.icon = val
        // this.iconlist=val
        // this.iconlist.some(item=>{
        //     console.log(item);
        // })
      },
      loginPop() {
        console.log(this.loginStatus);
        this.echarts()
      },
    },
  
  }
  </script>
  <style scoped lang="less">
.echartsBtns {
  position: absolute;
  top: 30px;
  left: 30px;
}
.echartsBtns ::v-deep button {
  padding: 4px 15px !important;
  font-size: 15px;
  background-color: transparent;
}
  .cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
  }
// /deep/ .el-table th.is-leaf{
// padding-left: 30px;
//   }
//   /deep/ .el-table td{
//   padding-left: 36px;
//   }
  .Company Snapshot {}
  
  .Country Data {}
  
  .title,
  .ecahrts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 69px;
  
    .title-left {
      display: flex;
      font-size: 16px;
      font-family: Arial-BoldMT, Arial;
      font-weight: normal;
      color: #022955;
    }
  }
  
  .footer {
    margin-top: 20px;
    padding:  20px;

  overflow: hidden;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
  }
  .ecahrts-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title-left{
      display: flex;
    }
  }
  .table {
    margin-top: 20px;
  }
  
  .trendEchartBox1 {
    width: 640px;
    height: 524px;
  }
  
  .ecahrtstu {
  display: flex;
  justify-content: center;
  position: relative;
    width: 980px;
  
    background: #FFFFFF;
  
  
  }
  .table_box /deep/ .el-table th>.cell:first-child {
    // padding-left: 12px;
}

.company_box .el-checkbox {
  width: 50%;
  margin-bottom: 20px;
  margin-right: 0;
}

.company_box .comp_top_check .el-checkbox {
  margin-bottom: 10px;
  margin-left: 20px;
}

.company_box /deep/ .el-checkbox__label {
  color: #022955;
}

.company_box /deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
  color: #022955;
}

.company_box /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.company_box /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #022955;
  border-color: #022955;
}

.company_box /deep/ .el-checkbox__inner {
  width: 20px;
  height: 20px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #8497ab;
}

.company_box /deep/ .el-checkbox__inner::after {
  height: 10px;
  left: 6px;
  top: 1px;
  width: 5px;
  border-width: 2px;
}

.comp_search {
  position: relative;
  width: 300px;
  margin-bottom: 10px;
}

.comp_search .coll_search_btn {
  top: 7px;
}
.company_b_txt {
  font-size: 12px;
  color: #8497ab;
  line-height: 16px;
}

.btn_export_left {
  width: 30px;
  height: 30px;
  background: #1290c9;
  border-radius: 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create_item_lab {
  font-size: 16px;
  font-family: 'Arial Bold';
  color: #022955;
  margin-bottom: 8px;
}

.create_item_num {
  font-size: 14px;
  font-family: 'Arial Bold';
  color: #8497ab;
}

.create_inpt {
  width: 100%;
  height: 46px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #e7eaed;
  font-size: 16px;
  padding: 13px 20px;
  margin-bottom: 20px;
}

.btn_comp_box {
  text-align: right;
}

.check_item {
  position: relative;
  max-height: 540px;
  overflow: auto;
  background: #f2f5f9;
  border-radius: 8px;
  border: 1px solid #e7eaed;
  padding: 20px 20px 0;
  margin-bottom: 10px;
}

.check_item::after {
  content: '';
  position: absolute;
  left: 48%;
  top: 20px;
  bottom: 20px;
  width: 2px;
  background-color: #e7eaed;
}

  </style>